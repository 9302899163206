import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER,

  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  GET_RESERVATION,
  GET_RESERVATION_SUCCESS,

  GET_SSS,
  GET_SSS_SUCCESS,
  ADD_SSS,
  ADD_SSS_SUCCESS,
  DELETE_SSS_SUCCESS,
  DELETE_SSS,
  UPDATE_SSS_SUCCESS,
  UPDATE_SSS,




  GET_ADVERT,
  GET_ADVERT_SUCCESS,
  ADD_ADVERT,
  ADD_ADVERT_SUCCESS,
  DELETE_ADVERT_SUCCESS,
  DELETE_ADVERT,
  UPDATE_ADVERT_SUCCESS,
  UPDATE_ADVERT,

  GET_AVAILABLE,
  GET_AVAILABLE_SUCCESS,
  ADD_AVAILABLE,
  ADD_AVAILABLE_SUCCESS,
  DELETE_AVAILABLE_SUCCESS,
  DELETE_AVAILABLE,
  UPDATE_AVAILABLE_SUCCESS,
  UPDATE_AVAILABLE,


  GET_OFFER,
  GET_OFFER_SUCCESS,
  ADD_OFFER,
  ADD_OFFER_SUCCESS,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER,


 
  DELETE_USER_FAIL,
  GET_POPUP,
  GET_WORKSHOP,
  GET_CourseSchedulesMega,
  GET_WorkshopSessionMega,
  GET_TeacherMega,
  GET_EVENT,
  GET_DYNAMICFORM,
  GET_SLIDER,
  GET_PROJECTSNEW,
  GET_NEWS,
  GET_MEGA,
  GET_CONTACT,
  GET_ABOUT,
  GET_AUTHORITY,
  GET_WORKSHOP2,
  GET_WORKSHOP2_SUCCESS,
  GET_GALLERY,
  GET_GALLERY_SUCCESS,
  GET_REFERANCES,
  ADD_REFERANCES,
  GET_SUBPROJECT,
  UPDATE_AUTH,
  UPDATE_WORKSHOP2,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_CONTACT_SUCCESS,

  UPDATE_CONTACT,

  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION,

  UPDATE_ABOUT_SUCCESS,
  UPDATE_ABOUT,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_GALLERY,
  ADD_LESSONS_TIME,

  UPDATE_LESSONS_TIME,
  GET_TEACHER_WORKSHOP,
  GET_REFERANCES_SUCCESS,
  ADD_WORKSHOP2,
  ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN,
  ADD_COURSE,
  ADD_MEGA,
  ADD_NEWS,

  ADD_PROJECT,
  ADD_SLIDER,
  ADD_PRODUCTCATEGORY,
 
  ADD_BLOG,

  ADD_POPUP,
  UPDATE_NEWS,
  UPDATE_PROJECT,
  UPDATE_SLIDER,

  UPDATE_TEACHER,
  UPDATE_WORKSHOPSESSION,
  UPDATE_COURSESCHEDULES,
  UPDATE_SUBDOMAIN,
  UPDATE_POPUP,
  GET_POPUP_SUCCESS,
  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SUCCESS_PROJECT,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_MEGA,
  UPDATE_SUCCESS_GALLERY,

  UPDATE_REFERANCES,
  UPDATE_REFERANCES_SUCCESS,

  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,

  ADD_LESSONSTIME_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,

  GET_CLASSATTENDANCE,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMIC_FORM,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORM_EXTRA,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORM_EXTRA_EXTRA,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,

  DELETE_MEGA,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,

  DELETE_NEWS_SUCCESS,
  DELETE_NEWS,


  DELETE_POPUP,
  DELETE_POPUP_SUCCESS,

  DELETE_REFERANCES,
  DELETE_REFERANCES_SUCCESS,

  ADD_GALLERY,
  ADD_GALLERY_SUCCESS,

  ADD_USER_SUCCESS,

  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,

  ADD_RESERVATION,
  ADD_RESERVATION_SUCCESS,


  DELETE_RESERVATION,
  DELETE_RESERVATION_SUCCESS,

  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,


  DELETE_USER_SUCCESS,

  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,


  DELETE_GALLERY,
  DELETE_GALLERY_SUCCESS,

  ADD_GALLERYMULTIPLE,
  ADD_GALLERYMULTIPLE_SUCCESS,

  DELETE_COURSESCHEDULES,
  DELETE_COURSESCHEDULES_SUCCESS,

  DELETE_DYNAMICFORMEXTRA,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,

  DELETE_DYNAMICFORM,
  DELETE_DYNAMICFORM_SUCCESS,

  DELETE_SLIDER,
  DELETE_SLIDER_SUCCESS,



  DELETE_BLOG,
  DELETE_BLOG_SUCCESS,

  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  DELETE_DYNAMICFORMEXTRAEXTRA,
  GET_EDUCATION, GET_EDUCATION_SUCCESS,

  GET_SUBEDUCATION,
  GET_SUBEDUCATION_SUCCESS,


  GET_PRODUCTCATEGORY,
  GET_PRODUCTCATEGORY_SUCCESS,

  ADD_EDUCATION,
  ADD_EDUCATION_SUCCESS,

  UPDATE_EDUCATION_SUCCESS,
  UPDATE_EDUCATION,


  UPDATE_PRODUCTCATEGORY_SUCCESS,
  UPDATE_PRODUCTCATEGORY,

  ADD_SUBEDUCATION_SUCCESS,
  ADD_SUBEDUCATION,

  UPDATE_SUBEDUCATION,
  UPDATE_SUBEDUCATION_SUCCESS,

  DELETE_SUBEDUCATION,
  DELETE_SUBEDUCATION_SUCCESS,

  UPDATE_BLOG,
  UPDATE_BLOG_SUCCESS,

  DELETE_PRODUCTCATEGORY,
  DELETE_PRODUCTCATEGORY_SUCCESS,

  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_SLİDERS,
  GET_SLİDERS_SUCCESS,
  GET_BLOGS,
  GET_BLOGS_SUCCESS,
  ADD_PRODUCTCATEGORY_SUCCESS,
  ADD_BLOG_SUCCESS,
  GET_ABOUT_SUCCESS,

  GET_PRODUCTSUBCATEGORY_SUCCESS,
  GET_PRODUCTSUBCATEGORY,

  GET_BRAND_SUCCESS,
  GET_BRAND,

  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,

  GET_PRODUCTSUBSUBCATEGORY,
GET_PRODUCTSUBSUBCATEGORY_SUCCESS,

ADD_SUBSUBCATEGORY,
ADD_SUBSUBCATEGORY_SUCCESS,

UPDATE_SUBSUBCATEGORY,
UPDATE_SUBSUBCATEGORY_SUCCESS,


ADD_BRAND,
ADD_BRAND_SUCCESS,

ADD_SUBCATEGORY,
ADD_SUBCATEGORY_SUCCESS,

UPDATE_PRODUCTSUBCATEGORY,
UPDATE_PRODUCTSUBCATEGORY_SUCCESS,
UPDATE_POPUP_SUCCESS,
ADD_POPUP_SUCCESS,

DELETE_PRODUCTSUBCATEGORY,
DELETE_PRODUCTSUBCATEGORY_SUCCESS,

DELETE_SUBSUBCATEGORY,
DELETE_SUBSUBCATEGORY_SUCCESS,

GET_LIMIT,
GET_LIMIT_SUCCESS,

UPDATE_LIMIT,
UPDATE_LIMIT_SUCCESS,

DELETE_LIMIT,
DELETE_LIMIT_SUCCESS,

ADD_LIMIT,
ADD_LIMIT_SUCCESS,
ADD_SLIDER_SUCCESS,

GET_PDR,
GET_PDR_SUCCESS,
ADD_PDR,
ADD_PDR_SUCCESS,
DELETE_PDR,
DELETE_PDR_SUCCESS,
UPDATE_PDR,
UPDATE_PDR_SUCCESS,

GET_SONG,
GET_SONG_SUCCESS,
ADD_SONG,
ADD_SONG_SUCCESS,
UPDATE_SONG,
UPDATE_SONG_SUCCESS,
DELETE_SONG,
DELETE_SONG_SUCCESS,

ADD_NEWPOST,
ADD_NEWPOST_SUCCESS,
DELETE_TEACHER,
DELETE_TEACHER_SUCCESS,


GET_SONG_CATEGORY,
GET_SONG_CATEGORY_SUCCESS,
ADD_SONG_CATEGORY,
ADD_SONG_CATEGORY_SUCCESS,
DELETE_SONG_CATEGORY_SUCCESS,
DELETE_SONG_CATEGORY,
UPDATE_SONG_CATEGORY_SUCCESS,
UPDATE_SONG_CATEGORY,

GET_POST,
GET_POST_SUCCESS,

UPDATE_POST,
UPDATE_POST_SUCCESS,

DELETE_POST,
DELETE_POST_SUCCESS,

ADD_MOTIVATION,
ADD_MOTIVATION_SUCCESS,
GET_MOTIVATION,
GET_MOTIVATION_SUCCESS,
UPDATE_MOTIVATION,
UPDATE_MOTIVATION_SUCCESS,
DELETE_MOTIVATION,
DELETE_MOTIVATION_SUCCESS,

GET_BOOK_SUCCESS,
GET_BOOK,
UPDATE_BOOK_SUCCESS,
UPDATE_BOOK,
DELETE_BOOK_SUCCESS,
DELETE_BOOK,
ADD_BOOK,
ADD_BOOK_SUCCESS,
GET_COUNTER,
GET_COUNTER_SUCCESS,
ADD_COUNTER,
ADD_COUNTER_SUCCESS,
UPDATE_COUNTER,
UPDATE_COUNTER_SUCCESS,
DELETE_COUNTER,
DELETE_COUNTER_SUCCESS,

GET_SALES_POINT,
GET_SALES_POINTSUCCESS,
ADD_SALES_POINT,
ADD_SALES_POINTSUCCESS,
UPDATE_SALES_POINT,
UPDATE_SALES_POINTSUCCESS,
DELETE_SALES_POINT,
DELETE_SALES_POINTSUCCESS,

GET_COACH,
GET_COACHSUCCESS,
ADD_COACH,
ADD_COACHSUCCESS,
UPDATE_COACH,
UPDATE_COACHSUCCESS,
DELETE_COACH,
DELETE_COACHSUCCESS,

GET_FEEDBACK,
GET_FEEDBACK_SUCCESS,
ADD_FEEDBACK,
ADD_FEEDBACK_SUCCESS,
DELETE_FEEDBACK_SUCCESS,
DELETE_FEEDBACK,
UPDATE_FEEDBACK_SUCCESS,
UPDATE_FEEDBACK,

GET_FEEDBACKCATEGORY,
GET_FEEDBACKCATEGORY_SUCCESS,
UPDATE_FEEDBACKCATEGORY,
UPDATE_FEEDBACKCATEGORY_SUCCESS,
ADD_FEEDBACKCATEGORY,
ADD_FEEDBACKCATEGORY_SUCCESS,

DELETE_FEEDBACKCATEGORY,
DELETE_FEEDBACKCATEGORY_SUCCESS,

GET_PURCH,
GET_PURCH_SUCCESS,
ADD_PURCH,
ADD_PURCH_SUCCESS,
UPDATE_PURCH,
UPDATE_PURCH_SUCCESS,
DELETE_PURCH,
DELETE_PURCH_SUCCESS,

GET_LIMIT_TEACHER,
GET_LIMIT_TEACHER_SUCCESS,

UPDATE_LIMITTEACHER,
UPDATE_LIMITTEACHER_SUCCESS,

ADD_LIMITTEACHER,
ADD_LIMITTEACHER_SUCCESS,

DELETE_LIMITTEACHER,
DELETE_LIMITTEACHER_SUCCESS,
} from "./actionTypes"

export const deleteFeedBackCategory= user => ({
  type: DELETE_FEEDBACKCATEGORY,
  payload: user,
})

export const deleteFeedBackCategorySuccess = user => ({
  type: DELETE_FEEDBACKCATEGORY_SUCCESS,
  payload: user,
})


export const addFeedbackCategory = user => ({
  type: ADD_FEEDBACKCATEGORY,
  payload: user,
})

export const addFeedbackCategorySuccess = user => ({
  type: ADD_FEEDBACKCATEGORY_SUCCESS,
  payload: user,
})

export const updateFeedbackCategory= user => ({
  type: UPDATE_FEEDBACKCATEGORY,
  payload: user,
})
export const updateFeedbackCategorySuccess  = user => ({
  type: UPDATE_FEEDBACKCATEGORY_SUCCESS,
  payload: user,
})


export const getFeedBackCategory= teacher => ({
  type: GET_FEEDBACKCATEGORY,
  payload: teacher,
})


export const getFeedBackCategorySuccess = teacher => ({
  type: GET_FEEDBACKCATEGORY_SUCCESS,
  payload: teacher,
})



export const getFeedBack= teacher => ({
  type: GET_FEEDBACK,
  payload: teacher,
})


export const getFeedBackSuccess = teacher => ({
  type: GET_FEEDBACK_SUCCESS,
  payload: teacher,
})



export const updateFeedBack= user => ({
  type: UPDATE_FEEDBACK,
  payload: user,
})
export const updateFeedBackSuccess  = user => ({
  type: UPDATE_FEEDBACK_SUCCESS,
  payload: user,
})


export const deleteFeedBack = user => ({
  type: DELETE_FEEDBACK,
  payload: user,
})

export const deleteFeedBackSuccess = user => ({
  type: DELETE_FEEDBACK_SUCCESS,
  payload: user,
})

export const addFeedBack = user => ({
  type: ADD_FEEDBACK,
  payload: user,
})

export const addFeedBackSuccess = user => ({
  type: ADD_FEEDBACK_SUCCESS,
  payload: user,
})


export const getPostCoach = teacher => ({
  type: GET_COACH,
  payload: teacher,
})


export const getPostCoachSuccess = teacher => ({
  type: GET_COACHSUCCESS,
  payload: teacher,
})


export const addNewPostCoach= user => ({
  type: ADD_COACH,
  payload: user,
})


export const addNewPostCoachSuccess = (user2) => ({
  type: ADD_COACHSUCCESS,
  payload: user2,
})

export const updatePostCoach= user => ({
  type: UPDATE_COACH,
  payload: user,
})
export const updatePostCoachSuccess  = user => ({
  type: UPDATE_COACHSUCCESS,
  payload: user,
})

export const deletePostCoach= user => ({
type: DELETE_COACH,
payload: user,
})

export const deletePostCoachSuccess = (user, ID) => ({
type: DELETE_COACHSUCCESS,
payload: ID,
ID: ID
})


export const getSalesPoint = teacher => ({
  type: GET_SALES_POINT,
  payload: teacher,
})


export const getSalesPointSuccess = teacher => ({
  type: GET_SALES_POINTSUCCESS,
  payload: teacher,
})


export const addSalesPoint= user => ({
  type: ADD_SALES_POINT,
  payload: user,
})


export const addSalesPointSuccess = (user2) => ({
  type: ADD_SALES_POINTSUCCESS,
  payload: user2,
})

export const updateSalesPoint= user => ({
  type: UPDATE_SALES_POINT,
  payload: user,
})
export const updateSalesPointSuccess  = user => ({
  type: UPDATE_SALES_POINTSUCCESS,
  payload: user,
})

export const deleteSalesPoint= user => ({
type: DELETE_SALES_POINT,
payload: user,
})

export const deleteSalesPointSuccess = (user, ID) => ({
type: DELETE_SALES_POINTSUCCESS,
payload: ID,
ID: ID
})



export const getPurch = teacher => ({
  type: GET_PURCH,
  payload: teacher,
})


export const getPurchSuccess = teacher => ({
  type: GET_PURCH_SUCCESS,
  payload: teacher,
})


export const addPurch= user => ({
  type: ADD_PURCH,
  payload: user,
})


export const addPurchSuccess = (user2) => ({
  type: ADD_PURCH_SUCCESS,
  payload: user2,
})

export const updatePurch= user => ({
  type: UPDATE_PURCH,
  payload: user,
})
export const updatePurchSuccess  = user => ({
  type: UPDATE_PURCH_SUCCESS,
  payload: user,
})

export const deletePurch= user => ({
type: DELETE_PURCH,
payload: user,
})

export const deletePurchSuccess = (user, ID) => ({
type: DELETE_PURCH_SUCCESS,
payload: ID,
ID: ID
})


  export const getCounter = teacher => ({
    type: GET_COUNTER,
    payload: teacher,
  })
  
  
  export const getCounterSuccess = teacher => ({
    type: GET_COUNTER_SUCCESS,
    payload: teacher,
  })
  

  export const addCounter= user => ({
    type: ADD_COUNTER,
    payload: user,
  })
  
  
  export const addCounterSuccess = (user2) => ({
    type: ADD_COUNTER_SUCCESS,
    payload: user2,
  })

  export const updateCounter= user => ({
    type: UPDATE_COUNTER,
    payload: user,
  })
  export const updateCounterSuccess  = user => ({
    type: UPDATE_COUNTER_SUCCESS,
    payload: user,
  })

export const deleteCounter= user => ({
  type: DELETE_COUNTER,
  payload: user,
})

export const deleteCounterSuccess = (user, ID) => ({
  type: DELETE_COUNTER_SUCCESS,
  payload: ID,
  ID: ID
})



export const getSongCategory = teacher => ({
  type: GET_SONG_CATEGORY,
  payload: teacher,
})


export const getSongCategorySuccess = teacher => ({
  type: GET_SONG_CATEGORY_SUCCESS,
  payload: teacher,
})



export const updateSongCategory= user => ({
  type: UPDATE_SONG_CATEGORY,
  payload: user,
})
export const updateSongCategorySuccess  = user => ({
  type: UPDATE_SONG_CATEGORY_SUCCESS,
  payload: user,
})


export const deleteSongCategory= user => ({
  type: DELETE_SONG_CATEGORY,
  payload: user,
})

export const deleteSongCategorySuccess = (user, ID) => ({
  type: DELETE_SONG_CATEGORY_SUCCESS,
  payload: user,
  ID: ID
})



export const addSongCategory= user => ({
  type: ADD_SONG_CATEGORY,
  payload: user,
})




export const addSongCategorySuccess = (user,user2) => ({
  type: ADD_SONG_CATEGORY_SUCCESS,
  payload: user2,
})


export const getSong = teacher => ({
  type: GET_SONG,
  payload: teacher,
})


export const getSongSuccess = teacher => ({
  type: GET_SONG_SUCCESS,
  payload: teacher,
})


export const addSong= user => ({
  type: ADD_SONG,
  payload: user,
})

export const addSongSuccess = user => ({
  type: ADD_SONG_SUCCESS,
  payload: user,
})


export const updateSong= user => ({
  type: UPDATE_SONG,
  payload: user,
})
export const updateSongSuccess  = user => ({
  type: UPDATE_SONG_SUCCESS,
  payload: user,
})



export const deleteSong= user => ({
  type: DELETE_SONG,
  payload: user,
})

export const deleteSongSuccess = (user, ID) => ({
  type: DELETE_SONG_SUCCESS,
  payload: user,
  ID: ID
})


export const deleteBook= user => ({
  type: DELETE_BOOK,
  payload: user,
})

export const deleteBookSuccess = (user,user2) => ({
  type: DELETE_BOOK_SUCCESS,
  payload: user2,
})


export const updateBook= user => ({
  type: UPDATE_BOOK,
  payload: user,
})
export const updateBookSuccess  = user => ({
  type: UPDATE_BOOK_SUCCESS,
  payload: user,
})


export const getBook= teacher => ({
  type: GET_BOOK,
  payload: teacher,
})


export const getBookSuccess = teacher => ({
  type: GET_BOOK_SUCCESS,
  payload: teacher,
})

export const addBook = user => ({
  type: ADD_BOOK,
  payload: user,
})

export const addBookSuccess = user => ({
  type: ADD_BOOK_SUCCESS,
  payload: user,
})


export const getProductSubCategory= teacher => ({
  type: GET_PRODUCTSUBCATEGORY,
  payload: teacher,
})


export const getProductSubCategorySuccess = teacher => ({
  type: GET_PRODUCTSUBCATEGORY_SUCCESS,
  payload: teacher,
})




export const getLimitTeacher= teacher => ({
  type: GET_LIMIT_TEACHER,
  payload: teacher,
})


export const getLimitTeacherSuccess = teacher => ({
  type: GET_LIMIT_TEACHER_SUCCESS,
  payload: teacher,
})

export const updateLimitTeacher= user => ({
  type: UPDATE_LIMITTEACHER,
  payload: user,
})
export const updateLimitTeacherSuccess  = user => ({
  type: UPDATE_LIMITTEACHER_SUCCESS,
  payload: user,
})


export const addLimitTeacher = user => ({
  type: ADD_LIMITTEACHER,
  payload: user,
})

export const addLimitTeacherSuccess = user => ({
  type: ADD_LIMITTEACHER_SUCCESS,
  payload: user,
})


export const deleteLimitTeacher= user => ({
  type: DELETE_LIMITTEACHER,
  payload: user,
})

export const deleteLimitTeacherSuccess = (user,user2) => ({
  type: DELETE_LIMITTEACHER_SUCCESS,
  payload: user2,
})
export const getLimit= teacher => ({
  type: GET_LIMIT,
  payload: teacher,
})


export const getLimitSuccess = teacher => ({
  type: GET_LIMIT_SUCCESS,
  payload: teacher,
})

export const updateLimit= user => ({
  type: UPDATE_LIMIT,
  payload: user,
})
export const updateLimitSuccess  = user => ({
  type: UPDATE_LIMIT_SUCCESS,
  payload: user,
})


export const addLimit = user => ({
  type: ADD_LIMIT,
  payload: user,
})

export const addLimitSuccess = user => ({
  type: ADD_LIMIT_SUCCESS,
  payload: user,
})


export const deleteLimit= user => ({
  type: DELETE_LIMIT,
  payload: user,
})

export const deleteLimitSuccess = (user,user2) => ({
  type: DELETE_LIMIT_SUCCESS,
  payload: user2,
})


export const addMotivation = user => ({
  type: ADD_MOTIVATION,
  payload: user,
})

export const addMotivationSuccess = user => ({
  type: ADD_MOTIVATION_SUCCESS,
  payload: user,
})

export const getMotivation= teacher => ({
  type: GET_MOTIVATION,
  payload: teacher,
})


export const getMotivationSuccess = teacher => ({
  type: GET_MOTIVATION_SUCCESS,
  payload: teacher,
})


export const  updateMotivation= user => ({
  type: UPDATE_MOTIVATION,
  payload: user,
})
export const updateMotivationSuccess  = user => ({
  type: UPDATE_MOTIVATION_SUCCESS,
  payload: user,
})

export const deleteMotivation = user => ({
  type: DELETE_MOTIVATION,
  payload: user,
})

export const deleteMotivationSuccess = (user,user2) => ({
  type: DELETE_MOTIVATION_SUCCESS,
  payload: user2,
})


export const getAdvert= teacher => ({
  type: GET_ADVERT,
  payload: teacher,
})


export const getAdvertSuccess = teacher => ({
  type: GET_ADVERT_SUCCESS,
  payload: teacher,
})


export const updateSubSubProductCategory= user => ({
  type: UPDATE_SUBSUBCATEGORY,
  payload: user,
})
export const updateSubSubProductCategorySuccess  = user => ({
  type: UPDATE_SUBSUBCATEGORY_SUCCESS,
  payload: user,
})


export const updateAdvert= user => ({
  type: UPDATE_ADVERT,
  payload: user,
})
export const updateAdvertSuccess  = user => ({
  type: UPDATE_ADVERT_SUCCESS,
  payload: user,
})

export const deleteProductSubSubCategory= user => ({
  type: DELETE_SUBSUBCATEGORY,
  payload: user,
})

export const deleteProductSubSubCategorySuccess = (user,user2) => ({
  type: DELETE_SUBSUBCATEGORY_SUCCESS,
  payload: user2,
})

export const deleteAdvert= user => ({
  type: DELETE_ADVERT,
  payload: user,
})

export const deleteAdvertSuccess = user => ({
  type: DELETE_ADVERT_SUCCESS,
  payload: user,
})

export const addAdvert = user => ({
  type: ADD_ADVERT,
  payload: user,
})

export const addAdvertSuccess = user => ({
  type: ADD_ADVERT_SUCCESS,
  payload: user,
})

export const deletePost= user => ({
  type: DELETE_POST,
  payload: user,
})


export const deletePostSuccess = (user, ID) => ({
  type: DELETE_POST_SUCCESS,
  payload: user,
  ID: ID
})

export const getPost= teacher => ({
  type: GET_POST,
  payload: teacher,
})


export const getPostSuccess = teacher => ({
  type: GET_POST_SUCCESS,
  payload: teacher,
})



export const getAvailable= teacher => ({
  type: GET_AVAILABLE,
  payload: teacher,
})


export const getAvailableSuccess = teacher => ({
  type: GET_AVAILABLE_SUCCESS,
  payload: teacher,
})


export const updateProductSubCategory= user => ({
  type: UPDATE_PRODUCTSUBCATEGORY,
  payload: user,
})
export const updateProductSubCategorySuccess  = user => ({
  type: UPDATE_PRODUCTSUBCATEGORY_SUCCESS,
  payload: user,
})


export const updateAvailable= user => ({
  type: UPDATE_AVAILABLE,
  payload: user,
})
export const updateAvailableSuccess  = user => ({
  type: UPDATE_AVAILABLE_SUCCESS,
  payload: user,
})


export const deleteAvailable = user => ({
  type: DELETE_AVAILABLE,
  payload: user,
})

export const deleteAvailableSuccess = user => ({
  type: DELETE_AVAILABLE_SUCCESS,
  payload: user,
})

export const addAvailable = user => ({
  type: ADD_AVAILABLE,
  payload: user,
})

export const addAvailableSuccess = user => ({
  type: ADD_AVAILABLE_SUCCESS,
  payload: user,
})

export const addProductSubCategory = user => ({
  type: ADD_SUBCATEGORY,
  payload: user,
})

export const addProductSubCategorySuccess = user => ({
  type: ADD_SUBCATEGORY_SUCCESS,
  payload: user,
})


  export const getPdr= teacher => ({
    type: GET_PDR,
    payload: teacher,
  })


  export const getPdrSuccess = teacher => ({
    type: GET_PDR_SUCCESS,
    payload: teacher,
  })


  export const addPdr = user => ({
    type: ADD_PDR,
    payload: user,
  })
  
  export const addPdrSuccess = user => ({
    type: ADD_PDR_SUCCESS,
    payload: user,
  })

  
export const updatePdr= user => ({
  type: UPDATE_PDR,
  payload: user,
})
export const updatePdrSuccess  = user => ({
  type: UPDATE_PDR_SUCCESS,
  payload: user,
})

export const deletePdr = user => ({
  type: DELETE_PDR,
  payload: user,
})

export const deletePdrSuccess = (user,user2) => ({
  type: DELETE_PDR_SUCCESS,
  payload: user2,
})


export const getProductSubSubCategory= teacher => ({
  type: GET_PRODUCTSUBSUBCATEGORY,
  payload: teacher,
})


export const getProductSubSubCategorySuccess = teacher => ({
  type: GET_PRODUCTSUBSUBCATEGORY_SUCCESS,
  payload: teacher,
})


export const getOffer= teacher => ({
  type: GET_OFFER,
  payload: teacher,
})


export const getOfferSuccess = teacher => ({
  type: GET_OFFER_SUCCESS,
  payload: teacher,
})



export const updateOffer= user => ({
  type: UPDATE_OFFER,
  payload: user,
})
export const updateOfferSuccess  = user => ({
  type: UPDATE_OFFER_SUCCESS,
  payload: user,
})


export const deleteOffer = user => ({
  type: DELETE_OFFER,
  payload: user,
})

export const deleteOfferSuccess = user => ({
  type: DELETE_OFFER_SUCCESS,
  payload: user,
})

export const addOffer = user => ({
  type: ADD_OFFER,
  payload: user,
})

export const addOfferSuccess = user => ({
  type: ADD_OFFER_SUCCESS,
  payload: user,
})






export const deleteSubEducation = user => ({
  type: DELETE_SUBEDUCATION,
  payload: user,
})

export const deleteProductSubCategory = user => ({
  type: DELETE_PRODUCTSUBCATEGORY,
  payload: user,
})


export const deleteProductSubCategorySuccess = (user,user2) => ({
  type: DELETE_PRODUCTSUBCATEGORY_SUCCESS,
  payload: user2,
})

export const deleteProductCategorySuccess = (user,user2) => ({
  type: DELETE_PRODUCTCATEGORY_SUCCESS,
  payload: user2,
})



export const deleteProductCategory = user => ({
  type: DELETE_PRODUCTCATEGORY,
  payload: user,
})



export const deleteReservationSuccess = (user,user2) => ({
  type: DELETE_RESERVATION_SUCCESS,
  payload: user2,
})



export const deleteReservation = user => ({
  type: DELETE_RESERVATION,
  payload: user,
})


export const addReservation = user => ({
  type: ADD_RESERVATION,
  payload: user,
})

export const addReservationSuccess = user => ({
  type: ADD_RESERVATION_SUCCESS,
  payload: user,
})



export const deleteSubEducationSuccess = user => ({
  type: DELETE_SUBEDUCATION_SUCCESS,
  payload: user,
})



export const addProductSubSubCategory = user => ({
  type: ADD_SUBSUBCATEGORY,
  payload: user,
})

export const addProductSubSubCategorySuccess = user => ({
  type: ADD_SUBSUBCATEGORY_SUCCESS,
  payload: user,
})



export const addSubEducation = user => ({
  type: ADD_SUBEDUCATION,
  payload: user,
})

export const addSubEducationSuccess = user => ({
  type: ADD_SUBEDUCATION_SUCCESS,
  payload: user,
})


export const updateProduct = user => ({
  type: UPDATE_PRODUCT,
  payload: user,
})


export const updateProductSuccess = user => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: user,
})




export const deleteProduct = user => ({
  type: DELETE_PRODUCT,
  payload: user,
})

export const deleteProductSuccess = (user, ID) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: user,
  ID: ID
})





export const addProduct = user => ({
  type: ADD_PRODUCT,
  payload: user,
})

export const addProductSuccess = user => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: user,
})



export const updateSubEducation = user => ({
  type: UPDATE_SUBEDUCATION,
  payload: user,
})


export const updateSubEducationSuccess = user => ({
  type: UPDATE_SUBEDUCATION_SUCCESS,
  payload: user,
})


export const updateBlog = user => ({
  type: UPDATE_BLOG,
  payload: user,
})


export const updateBlogSuccess = user => ({
  type: UPDATE_BLOG_SUCCESS,
  payload: user,
})

export const updateProductCategory = user => ({
  type: UPDATE_PRODUCTCATEGORY,
  payload: user,
})


export const updateProductCategorySuccess = user => ({
  type: UPDATE_PRODUCTCATEGORY_SUCCESS,
  payload: user,
})

export const updateEducationSuccess = user => ({
  type: UPDATE_EDUCATION_SUCCESS,
  payload: user,
})



export const updateEducation = user => ({
  type: UPDATE_EDUCATION,
  payload: user,
})


export const addEducation = user => ({
  type: ADD_EDUCATION,
  payload: user,
})

export const addEducationSuccess = user => ({
  type: ADD_EDUCATION_SUCCESS,
  payload: user,
})

export const addMegaSuccess = user => ({
  type: ADD_MEGA_SUCCESS,
  payload: user,
})

export const updateDynamicFormSuccess = user => ({
  type: UPDATE_DYNAMICFORM_SUCCESS,
  payload: user,
})


export const updateDynamicForm = user => ({
  type: UPDATE_DYNAMICFORM,
  payload: user,
})

export const getClasses = () => ({
  type: GET_CLASSES,
})


export const getDynamicFormExtraExtraSuccess = user => ({
  type: GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  payload: user,
})


export const getDynamicFormExtraSuccess = user => ({
  type: GET_DYNAMICFORMEXTRA_SUCCESS,
  payload: user,
})


export const getClassesSuccess = user => ({
  type: GET_CLASSES_SUCCESS,
  payload: user,
})

export const getClassAttendanceSuccess = user => ({
  type: GET_CLASSATTENDANCE_SUCCESS,
  payload: user,
})


export const updateLessonsTimeSuccess = user => ({
  type: UPDATE_LESSONSTIME_SUCCESS,
  payload: user,
})


export const getTeacherWorkshopSuccess = teacher => ({
  type: GET_TEACHERWORKSHOP_SUCCESS,
  payload: teacher,
})

export const getSubProjectSuccess = teacher => ({
  type: GET_SUBPROJECT_SUCCESS,
  payload: teacher,
})


export const getReferancesSuccess = teacher => ({
  type: GET_REFERANCES_SUCCESS,
  payload: teacher,
})


export const updateGallerySuccess = user => ({
  type: UPDATE_SUCCESS_GALLERY,
  payload: user,
})


export const getAuthority = () => ({
  type: GET_AUTHORITY,
})



export const updateWorkShop2Success = user => ({
  type: UPDATE_WORKSHOP2_SUCCESS,
  payload: user,
})

export const updateReferances = user => ({
  type: UPDATE_REFERANCES,
  payload: user,
})
export const updateMega = user => ({
  type: UPDATE_MEGA,
  payload: user,
})

export const updateMegaSuccess = news => ({
  type: UPDATE_SUCCESS_MEGA,
  payload: news,
})

export const updateNewsSuccess = news => ({
  type: UPDATE_SUCCESS_NEWS,
  payload: news,
})

export const updateSuccessProject = teacher => ({
  type: UPDATE_SUCCESS_PROJECT,
  payload: teacher,
})

export const updateSliderSuccess = teacher => ({
  type: UPDATE_SLIDER_SUCCESS,
  payload: teacher,
})


export const updateTeacherSuccess = teacher => ({
  type: UPDATE_SUCCESS_TEACHER,
  payload: teacher,
})

export const updateSuccessCourseSchedules = teacher => ({
  type: UPDATE_SUCCESS_COURSESCHEDULES,
  payload: teacher,
})

export const getSubEducation = (teacher,type) => ({
  type: GET_SUBEDUCATION,
  payload: teacher,
  typeNew:type
})

export const getSubEducationSuccess = teacher => ({
  type: GET_SUBEDUCATION_SUCCESS,
  payload: teacher,
})


export const getProduct = teacher => ({
  type: GET_PRODUCT,
  payload: teacher,
})


export const getProductSuccess = teacher => ({
  type: GET_PRODUCT_SUCCESS,
  payload: teacher,
})


export const getSliders = teacher => ({
  type: GET_SLİDERS,
  payload: teacher,
})


export const getSlidersSuccess = teacher => ({
  type: GET_SLİDERS_SUCCESS,
  payload: teacher,
})


export const getBrand = teacher => ({
  type: GET_BRAND,
  payload: teacher,
})


export const getBrandSuccess = teacher => ({
  type: GET_BRAND_SUCCESS,
  payload: teacher,
})


export const updateBrand = user => ({
  type: UPDATE_BRAND,
  payload: user,
})
export const onUpdateBrandSuccess  = user => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: user,
})




export const getBlogs = teacher => ({
  type: GET_BLOGS,
  payload: teacher,
})


export const getBlogsSuccess = teacher => ({
  type: GET_BLOGS_SUCCESS,
  payload: teacher,
})




export const getReservation = teacher => ({
  type: GET_RESERVATION,
  payload: teacher,
})


export const getReservationSuccess = teacher => ({
  type: GET_RESERVATION_SUCCESS,
  payload: teacher,
})




export const updateReservation = user => ({
  type: UPDATE_RESERVATION,
  payload: user,
})
export const updateReservationSuccess  = user => ({
  type: UPDATE_RESERVATION_SUCCESS,
  payload: user,
})




export const getSSS = teacher => ({
  type: GET_SSS,
  payload: teacher,
})


export const getSSSSuccess = teacher => ({
  type: GET_SSS_SUCCESS,
  payload: teacher,
})



export const updateSSS = user => ({
  type: UPDATE_SSS,
  payload: user,
})
export const updateSSSSuccess  = user => ({
  type: UPDATE_SSS_SUCCESS,
  payload: user,
})



export const getProductCategory = teacher => ({
  type: GET_PRODUCTCATEGORY,
  payload: teacher,
})

export const getProductCategorySuccess = teacher => ({
  type: GET_PRODUCTCATEGORY_SUCCESS,
  payload: teacher,
})


export const getAuthUpdateSuccess = teacher => ({
  type: GET_AUTHUPDATE_SUCCESS,
  payload: teacher,
})

export const getAuthSuccess = teacher => ({
  type: GET_AUTH_SUCCESS,
  payload: teacher,
})
export const getDynamicSuccess = teacher => ({
  type: GET_DYNAMIC_SUCCESS,
  payload: teacher,
})

export const getTeacherSuccess = teacher => ({
  type: GET_TEACHER_SUCCESS,
  payload: teacher,
})

export const getGallerySuccess = mega => ({
  type: GET_GALLERY_SUCCESS,
  payload: mega,
})


export const getMegaSuccess = mega => ({
  type: GET_MEGA_SUCCESS,
  payload: mega,
})


export const getEducation = news => ({
  type: GET_EDUCATION,
  payload: news,
})


export const getEducationSuccess = news => ({
  type: GET_EDUCATION_SUCCESS,
  payload: news,
})


export const getWorkShop2 = news => ({
  type: GET_WORKSHOP2,
  payload: news,
})


export const getNewsSuccess = news => ({
  type: GET_NEWS_SUCCESS,
  payload: news,
})

export const getProjectSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const getSliderSuccess = slider => ({
  type: GET_SLIDER_SUCCESS,
  payload: slider,
})

export const getApplicationSuccess = workshop => ({
  type: GET_APPLICATION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSessionSuccess = workshop => ({
  type: GET_WORKSHOPSESSION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSuccess = workshop => ({
  type: GET_WORKSHOP_SUCCESS,
  payload: workshop,
})


export const updateWorkShopSessionSuccess = user => ({
  type: UPDATE_WORKSHOPSESSION_SUCCESS,
  payload: user,
})

export const updateSubdomainSuccess = user => ({
  type: UPDATE_SUBDOMAIN_SUCCESS,
  payload: user,
})

export const updateAuth = user => ({
  type: UPDATE_AUTH,
  payload: user,
})

export const updateContact = user => ({
  type: UPDATE_CONTACT,
  payload: user,
})
export const updateContactSuccess  = user => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: user,
})


export const updateAbout= user => ({
  type: UPDATE_ABOUT,
  payload: user,
})
export const updateAboutSuccess  = user => ({
  type: UPDATE_ABOUT_SUCCESS,
  payload: user,
})

export const updateNews = user => ({
  type: UPDATE_NEWS,
  payload: user,
})


export const updateProject = user => ({
  type: UPDATE_PROJECT,
  payload: user,
})

export const updateSlider = user => ({
  type: UPDATE_SLIDER,
  payload: user,
})


export const getWorkshop2Success = popup => ({
  type: GET_WORKSHOP2_SUCCESS,
  payload: popup,
})


export const getPopupSuccess = popup => ({
  type: GET_POPUP_SUCCESS,
  payload: popup,
})


export const updateTeacher = user => ({
  type: UPDATE_TEACHER,
  payload: user,
})


export const updateWorkshop2 = user => ({
  type: UPDATE_WORKSHOP2,
  payload: user,
})


export const updateWorkshopSession = user => ({
  type: UPDATE_WORKSHOPSESSION,
  payload: user,
})



export const updateCourseSchedules = user => ({
  type: UPDATE_COURSESCHEDULES,
  payload: user,
})

export const updateSubdomain = user => ({
  type: UPDATE_SUBDOMAIN,
  payload: user,
})


export const updatePopup = user => ({
  type: UPDATE_POPUP,
  payload: user,
})

export const updatePopupSuccess = user => ({
  type: UPDATE_POPUP_SUCCESS,
  payload: user,
})



export const getClassAttendance = () => ({
  type: GET_CLASSATTENDANCE,
})


export const getTeacherWorkshopMega = () => ({
  type: GET_TEACHER_WORKSHOP,
})

export const getReferances = () => ({
  type: GET_REFERANCES,
})

export const getContactMega = () => ({
  type: GET_CONTACT,
})
export const getAbout = () => ({
  type: GET_ABOUT
})


export const getMegaMega = () => ({
  type: GET_MEGA,
})

export const getNews = () => ({
  type: GET_NEWS,
})

export const getSubProject = news => ({
  type: GET_SUBPROJECT,
  news,
})


export const getProjectsNew = () => ({
  type: GET_PROJECTSNEW,
})


export const getSlider = () => ({
  type: GET_SLIDER,
})

export const getDynamicForm = () => ({
  type: GET_DYNAMICFORM,
})


export const getEventMega = () => ({
  type: GET_EVENT,
})


export const getTeacherMega = (teacher) => ({
  type: GET_TeacherMega,
  payload: teacher,
})


export const getWorkshopSessionMega = () => ({
  type: GET_WorkshopSessionMega,
})

export const deleteTeacher= user => ({
  type: DELETE_TEACHER,
  payload: user,
})

export const deleteTeacherSuccess = user => ({
  type: DELETE_TEACHER_SUCCESS,
  payload: user,
})

export const updatePost = user => ({
  type: UPDATE_POST,
  payload: user,
})

export const updatePostSuccess = user => ({
  type: UPDATE_POST_SUCCESS,
  payload: user,
})



export const addNewPost = user => ({
  type: ADD_NEWPOST,
  payload: user,
})


export const addNewPostSuccess = user => ({
  type: ADD_NEWPOST_SUCCESS,
  payload: user,
})


export const getWorkShop = () => ({
  type: GET_WORKSHOP,
})

export const getPopup = () => ({
  type: GET_POPUP,
})

export const getCourseSchedulesMega = () => ({
  type: GET_CourseSchedulesMega,
})



export const getGallery = () => ({
  type: GET_GALLERY,
})

export const getUsers = () => ({
  type: GET_USERS,
})

export const getDynamicFormExtra = users => ({
  type: GET_DYNAMICFORM_EXTRA,
  users,
})

export const getDynamicFormExtraExtra = users => ({
  type: GET_DYNAMICFORM_EXTRA_EXTRA,
  users,
})


export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getAboutSuccess = users => ({
  type: GET_ABOUT_SUCCESS,
  payload: users,
})

export const addDynamicFormSuccess = user => ({
  type: ADD_DYNAMICFORM_SUCCESS,
  payload: user,
})

export const addDynamicForm = user => ({
  type: ADD_DYNAMIC_FORM,
  payload: user,
})


export const addBrand = user => ({
  type: ADD_BRAND,
  payload: user,
})

export const addBrandSuccess = user => ({
  type: ADD_BRAND_SUCCESS,
  payload: user,
})



export const addLessonsTime = user => ({
  type: ADD_LESSONS_TIME,
  payload: user,
})

export const addReferances = user => ({
  type: ADD_REFERANCES,
  payload: user,
})

export const updateSuccessReferances = user => ({
  type: UPDATE_REFERANCES_SUCCESS,
  payload: user,
})




export const addSSS = user => ({
  type: ADD_SSS,
  payload: user,
})
export const addSSSSuccess = user => ({
  type: ADD_SSS_SUCCESS,
  payload: user,
})


export const updateSuccessSSS = user => ({
  type: UPDATE_SSS_SUCCESS,
  payload: user,
})
export const addNewWorkshop2 = user => ({
  type: ADD_WORKSHOP2,
  payload: user,
})


export const addAppSetting = user => ({
  type: ADD_APPSETTING,
  payload: user,
})

export const addWorkshopSubdomain = user => ({
  type: ADD_WORKSHOPSUBDOMAIN,
  payload: user,
})

export const addCourse = user => ({
  type: ADD_COURSE,
  payload: user,
})

export const addNewMega = user => ({
  type: ADD_MEGA,
  payload: user,
})

export const addNews = user => ({
  type: ADD_NEWS,
  payload: user,
})

export const addProject = user => ({
  type: ADD_PROJECT,
  payload: user,
})

export const addSlider = user => ({
  type: ADD_SLIDER,
  payload: user,
})


export const addSliderSuccess = user => ({
  type: ADD_SLIDER_SUCCESS,
  payload: user,
})



export const addProductCategory = user => ({
  type: ADD_PRODUCTCATEGORY,
  payload: user,
})




export const addProductCategorySuccess = user => ({
  type: ADD_PRODUCTCATEGORY_SUCCESS,
  payload: user,
})




export const addBlog = user => ({
  type: ADD_BLOG,
  payload: user,
})
export const addBlogSuccess = user => ({
  type: ADD_BLOG_SUCCESS,
  payload: user,
})


export const addPopup = user => ({
  type: ADD_POPUP,
  payload: user,
})

export const addPopupSuccess = user => ({
  type: ADD_POPUP_SUCCESS,
  payload: user,
})



export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})


export const addUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})


export const addSuccessLessonsTime = user => ({
  type: ADD_LESSONSTIME_SUCCESS,
  payload: user,
})


export const addSuccessReferances = user => ({
  type: ADD_REFERANCES_SUCCESS,
  payload: user,
})


export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const updateLessonsTime = user => ({
  type: UPDATE_LESSONS_TIME,
  payload: user,
})

export const updateGallery = user => ({
  type: UPDATE_GALLERY,
  payload: user,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})


export const deleteSSS = user => ({
  type: DELETE_SSS,
  payload: user,
})

export const deleteSSSSuccess = user => ({
  type: DELETE_SSS_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})


export const deleteMega = user => ({
  type: DELETE_MEGA,
  payload: user,
})

export const deleteMegaSuccess = (user, ID) => ({
  type: DELETE_MEGA_SUCCESS,
  payload: user,
  ID: ID
})

export const deleteNews = user => ({
  type: DELETE_NEWS,
  payload: user,
})



export const deleteNewsSuccess = (user, ID) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: user,
  ID: ID
})

export const deleteSlider = user => ({
  type: DELETE_SLIDER,
  payload: user,
})

export const deleteSliderSuccess = (user, ID) => ({
  type: DELETE_SLIDER_SUCCESS,
  payload: user,
  ID: ID
})


export const deleteBlog = user => ({
  type: DELETE_BLOG,
  payload: user,
})

export const deleteBlogSuccess = (user, ID) => ({
  type: DELETE_BLOG_SUCCESS,
  payload: user,
  ID: ID
})

export const deletePopup = user => ({
  type: DELETE_POPUP,
  payload: user,
})

export const deletePopupSuccess = (user, ID) => ({
  type: DELETE_POPUP_SUCCESS,
  payload: user,
  ID: ID
})


export const deleteReferances = user => ({
  type: DELETE_REFERANCES,
  payload: user,
})

export const deleteReferancesSuccess = (user, ID) => ({
  type: DELETE_REFERANCES_SUCCESS,
  payload: user,
  ID: ID
})

export const addGallerySuccess = user => ({
  type: ADD_GALLERY_SUCCESS,
  payload: user,
})

export const addGallery = user => ({
  type: ADD_GALLERY,
  payload: user,
})

export const deleteGallery = user => ({
  type: DELETE_GALLERY,
  payload: user,
})

export const deleteGallerySuccess = (user, ID) => ({
  type: DELETE_GALLERY_SUCCESS,
  payload: user,
  ID: ID
})


export const addGalleryMultipleSuccess = user => ({
  type: ADD_GALLERYMULTIPLE_SUCCESS,
  payload: user,
})

export const addGalleryMultiple = user => ({
  type: ADD_GALLERYMULTIPLE,
  payload: user,
})

export const deleteCourseSchedules = user => ({
  type: DELETE_COURSESCHEDULES,
  payload: user,
})

export const deleteCourseSchedulesSuccess = (user, ID) => ({
  type: DELETE_COURSESCHEDULES_SUCCESS,
  payload: user,
  ID: ID
})

export const deleteDynamicFormExtra = user => ({
  type: DELETE_DYNAMICFORMEXTRA,
  payload: user,
})

export const deleteDynamicExtraFormSuccess = (user, ID) => ({
  type: DELETE_DYNAMICFORMEXTRA_SUCCESS,
  payload: user,
  ID: ID
})

export const deleteDynamicForm = user => ({
  type: DELETE_DYNAMICFORM,
  payload: user,
})

export const deleteDynamicFormSuccess = (user, ID) => ({
  type: DELETE_DYNAMICFORM_SUCCESS,
  payload: user,
  ID: ID
})



export const deleteDynamicFormExtraExtra = user => ({
  type: DELETE_DYNAMICFORMEXTRAEXTRA,
  payload: user,
})

export const deleteDynamicExtraExtraFormSuccess = (user, ID) => ({
  type: DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  payload: user,
  ID: ID
})
