import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css'; // Takvim için gerekli CSS dosyası
import './EventCard.css'; // Takvim için gerekli CSS dosyası


import {
  getProductSubSubCategory as onGetProductSubSubCategory,
  addProductSubSubCategory as onAddProductSubSubCategory,
  updateSubSubProductCategory as onUpdateSubSubProductCategory,
  deleteProductSubSubCategory as onDeleteProductSubSubCategory,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Ödevler | EfsoKoç Dijital Kullanıcı PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [getQuestionTitle, setQuestionTitle] = useState(null);


  const { users } = useSelector(state => ({
    users: state.contacts.productSubSubCategory,
  }));

  const [modal, setModal] = useState(false);
  

  const [selectedUserID, setSelectedUserID] = useState(null);
  const [getCalendar, setCalendar] = useState(null);

  const [isEdit, setIsEdit] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [getSelectedLessonID, setSelectedLessonID] = useState(null);
  
  const [getFilterCheck, setFilterCheck] = useState(false);

  const [getCategory, setCategory] = useState([]);
  const [getTeacherOfUser, setTeacherOfUser] = useState([]);

  const [getPlan, setPlan] = useState([]);
  const [markedDates, setMarkedDates] = useState([]);
  const [getUserType, setUserType] = useState(null);
  const [getUserID, setUserID] = useState(null);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCalendar(value); 
  };
  

  const handleInputTitle = (e) => {
    console.log("smdfşl",e.target.value)
    setQuestionTitle(e.target.value);
  };

  const _getAuthData = async () => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonEfso/select/${authUser.Users_Lessons_Type_ID}`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  const _getAllHomework = async () => {
    try {
      let authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser != null) {
        if (authUser.Users_Type_ID === 2) {
          await axios
            .get(`https://sihaadmin.stechomeyazilim.info:8182/getAllHomeworkUser/select/${authUser.ID}/`)
            .then((rsp) => {
              const dates = [];
              
              // Eski planı ve gelen yeni planı karşılaştır
                setPlan(rsp.data); // Eğer farklıysa planı güncelle
           
  
              rsp.data.forEach((event) => {
                const startDate = moment(event.Homework_DateTime).format('YYYY-MM-DD');
                if (!dates.includes(startDate)) {
                  dates.push(startDate);
                }
              });
  
                setMarkedDates((oldArray) => [...oldArray, ...dates]); // Sadece farklıysa planı güncelle

            });
        } else {
          if (authUser.Teacher.length > 0) {
            await axios
              .get(`https://sihaadmin.stechomeyazilim.info:8182/getTeacherOfUserEfso2/select/${authUser.Teacher[0].ID}/`)
              .then(async (rsp1) => {

                setTeacherOfUser(rsp1.data)

                rsp1.data.map(async (item, index) => {
                  await axios
                    .get(`https://sihaadmin.stechomeyazilim.info:8182/getAllHomeworkUser/select/${item.Users_ID}/`)
                    .then((rsp) => {
                      const dates = [];
                        setPlan((oldArray) => [...oldArray, ...rsp.data]); // Sadece farklıysa planı güncelle
                    
                      rsp.data.forEach((event) => {
                        const startDate = moment(event.Homework_DateTime).format('YYYY-MM-DD');
                        if (!dates.includes(startDate)) {
                          dates.push(startDate);
                        }
                      });
  
                        setMarkedDates((oldArray) => [...oldArray, ...dates]); // Sadece farklıysa planı güncelle
                   
                    });
                });
              });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    if (getPlan && getPlan.length === 0) {
      _getAllHomework();
    }
  }, [getPlan]);
  
  useEffect(() => {
    if (users && !users.length) {

      let authUser = JSON.parse(localStorage.getItem("authUser"));

      setUserType(authUser.Users_Type_ID)

      if (authUser.Users_Type_ID == 2) {
        dispatch(onGetProductSubSubCategory(authUser.ID))
        setIsEdit(false);
      }
      else{
        dispatch(onGetProductSubSubCategory(null))

        if(authUser.Teacher.length > 0){
          setUserID(authUser.Teacher[0].ID)

        }

        setIsEdit(false);

      }
                }
  }, [dispatch, users]);

  useEffect(() => {
    _getAuthData()
   
  }, [getCategory]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteProductSubSubCategory(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };



  const _selectStatus = async(status,ID) => {
  
    try {

      await axios.patch(`https://sihaadmin.stechomeyazilim.info:8182/updateHomeWorkEfso/${ID}`, {
        Homework_Status : status
      })
      .then(async (res) => {
        _getAllHomework()
      })

     
    

    } catch (error) {
      console.error('Paylaşım hatası:', error);
    }

     
  };

  const handleUserReset = (arg) => {
    setFilterCheck(false)
  };

  const handleDateClick = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setSelectedDate(formattedDate);
    const filtered = getPlan.filter(event => moment(event.Homework_DateTime).format('YYYY-MM-DD') === formattedDate);
    setFilteredData(filtered);
    setFilterCheck(true)
  };


  const renderWeeklySchedule = () => {
    const daysOfWeek = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];

    const weekStart = moment().startOf('week'); 

    const weekSchedule = daysOfWeek.map((day, index) => {
      const currentDay = weekStart.clone().format('YYYY-MM-DD');

      const events = getPlan.filter(event => event.Homework_DateTime.split('T')[0] === currentDay);

      return (
        events.length > 0 ? 
        events.map((item, index) =>{  
          return(

          <div key={item.ID} className="card-container">
            <p className="created-date">
            { moment(item.Created_DateTime).format('DD/MM/YYYY')}
            </p>

            <div className="event-info-row">
              <div>
                <div className="info-item">
                  <p className="event-label">Tarih</p>
                </div>
                <div className="info-item">
                  <p className="event-label">Gün</p>
                </div>
                <div className="info-item">
                  <p className="event-label">Saat</p>
                </div>
                {events.length > 0 && item.Lesson && (
                  <div className="info-item">
                    <p className="event-label">Ders</p>
                  </div>
                )}
                {events.length > 0 && item.HomeWork_Note && (
                  <div className="info-item">
                    <p className="event-label">Not</p>
                  </div>
                )}
                {events.length > 0 && item.Homework_Status && (
                  <div className="info-item">
                    <p className="event-label">Durumu</p>
                  </div>
                )}

{ getUserType == 3 ?  <div className="info-item">
                    <p className="event-label">Kullanıcı</p>
                  </div> : null }

              </div>

              <div>
                <div className="info-item">
                  <p className="event-info">{currentDay}</p>
                </div>
                <div className="info-item">
                  <p className="event-info">{day}</p>
                </div>
                <div className="info-item">
                  <p className="event-info">
                    {events.length > 0 ? `${item.Homework_DateTime.split('T')[1].slice(0, 5)}` : ''}
                  </p>
                </div>
                {events.length > 0 && item.Lesson && (
                  <div className="info-item">
                    <p className="event-info">{item.Lesson.Lesson_Title}</p>
                  </div>
                )}
                {events.length > 0 && item.HomeWork_Note && (
                  <div className="info-item">
                    <p className="event-info">{item.HomeWork_Note}</p>
                  </div>
                )}
                {events.length > 0 && item.Homework_Status && (
                  <div className="info-item">
                    <p className="event-info">{item.Homework_Status}</p>
                  </div>
                )}

{ getUserType == 3 ?  <div className="info-item">
                    <p className="event-info">{item.Users.Users_NameSurname}</p>
                  </div> : null }
              </div>
            </div>

            <div className="button-row">
              {events.length > 0 && item.Homework_Status !== 'yapıldı' && (
                <button className="status-button green" onClick={() => _selectStatus('yapıldı', item.ID)}>
                  Yapıldı
                </button>
              )}
              {events.length > 0 && item.Homework_Status !== 'eksik' && (
                <button className="status-button gray" onClick={() => _selectStatus('eksik', item.ID)}>
                  Eksik Yaptı
                </button>
              )}
              {events.length > 0 && item.Homework_Status !== 'yapılmadı' && (
                <button className="status-button red" onClick={() => _selectStatus('yapılmadı', item.ID)}>
                  Yapılmadı
                </button>
              )}
            </div>
          </div>
        ) }): null
      )
    });

    return weekSchedule; 
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = moment(date).format('YYYY-MM-DD');
   
      return markedDates.includes(formattedDate) ? 'highlighted' : null
    }
    return null;
  };
  const [contactEdit, setEditContact] = useState();


  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);


    setIsEdit(true);

    toggle();
  };


  function showToast(type,message,type2) {

    let ele = null
  
     if(type2 == true){
       ele = "success"
       message = message
    } else{
       ele = "error"
       message = message 
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // API endpoint URL
      const apiUrl = 'https://sihaadmin.stechomeyazilim.info:8182/addHomeworkEfso/send'; // API URL'inizi buraya koyun

      const testDateUtc = moment.utc();
      const localDate = moment(testDateUtc).local();
      console.log("lpmsdlf123",
        getUserID,
        selectedUserID,
        localDate,
        getCalendar,
        getQuestionTitle,
        getSelectedLessonID
       // Homework_Status
               )
       
               let authUser = JSON.parse(localStorage.getItem("authUser"));

      const response = await axios.post(apiUrl, {
        Teacher_ID:getUserID,
        Users_ID: getUserType == 3 ? selectedUserID : authUser.ID,
        Created_DateTime:localDate,
        Homework_DateTime:getCalendar,
        HomeWork_Note:getQuestionTitle,
        Lesson_ID:getSelectedLessonID
       // Homework_Status
               })

               console.log("şlmsdlf",response)

   
      if (response.status === 200) {
        showToast('Efso Dijital', "Veriler başarıyla işlendi!", true)
        setModal(!modal);
      
        
       
          setTimeout(() =>  {
           
            _getAllHomework();

          }, 1000)
      } else {

        showToast('Efso Dijital', "Veriler işlenirken bir hata oluştu!", false)

      }
    } catch (error) {
      console.error('Error submitting data:', error);
      showToast('Efso Dijital', "Veriler işlenirken bir hata oluştu!", false)
    }
  };

  
  const handleCategoryLesson = async(e) => {


    const selectedID = e.target.value;
    setSelectedLessonID(selectedID);
    
  };


  const handleCategoryChange = async(e) => {
    const selectedID = e.target.value;
    setSelectedUserID(selectedID);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Ödev Listesi" />


          <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Ödev Ekle"}
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                        <Row>
                          <Col xs={12}>
                      
            

                          <div className="mb-3">
                            <Label className="form-label">Ders Seçiniz</Label>
                              <Input
                                name="getSelectedLessonID"
                                type="select"
                                className="form-select"
                                defaultValue={getSelectedLessonID}
                                onChange={handleCategoryLesson}
                                value={getSelectedLessonID}>
                                 <option value="">Lütfen Seçim Yapınız</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Lesson_Title}</option>
                                ))}

                              </Input>
                             
                            </div>


                          {  getUserType == 3 ?
                            <div className="mb-3">
                            <Label className="form-label">Öğrenci Seçiniz</Label>
                              <Input
                                name="selectedUserID"
                                type="select"
                                className="form-select"
                                defaultValue={selectedUserID}
                                onChange={handleCategoryChange}
                                value={selectedUserID}>
                                 <option value="">Lütfen Seçim Yapınız</option>

                                {getTeacherOfUser.map((item, index) => (
                                  <option value={item.Users.ID} key={item.Users.ID}>{item.Users.Users_NameSurname}</option>
                                ))}

                              </Input>
                             
                            </div>
                        : null}
                         

                         
                            <div className="mb-3">
                              <Label className="form-label">Notunuz</Label>
                              <Input
                                name="titleText"
                                label="titleText"
                                type="text"
                                placeholder="Notunuz Adı Giriniz"
                                value={getQuestionTitle}
                                onChange={handleInputTitle}
                               
                              />
                              
                            </div>

                            <div className="mb-3">
            <Label className="form-label">Tarih</Label>
            <Input
              type="date"
              name="date"
              value={getCalendar}
              onChange={handleInputChange}
            />
          </div>

                           


                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </ModalBody>
                  </Modal>

               {   getUserType == 3 ? 
          <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus me-1" />
                Ödev Ekle
              </Button>
            </div> : null}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

              
             
      <div className="calendar-container">
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          tileClassName={tileClassName}
          onClickDay={handleDateClick}
                  />
        </div>
   
        { getFilterCheck == true ?  
        <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleUserReset}
              >
                <i className="mdi mdi-refresh me-1" />
                Haftalık Planlamayı Göster
              </Button> : 
             null}

        
{ getFilterCheck == true ? 
        filteredData.map((item, index) => (
          
          <div key={index} className="card-container">
          <p className="created-date">
          { moment(item.Created_DateTime).format('DD/MM/YYYY')}
          </p>

          <div className="event-info-row">
            <div>
              <div className="info-item">
                <p className="event-label">Tarih</p>
              </div>
              <div className="info-item">
                <p className="event-label">Gün</p>
              </div>
              <div className="info-item">
                <p className="event-label">Saat</p>
              </div>
             { item.Lesson && (
                <div className="info-item">
                  <p className="event-label">Ders</p>
                </div>
              )}
            {item.HomeWork_Note && (
                <div className="info-item">
                  <p className="event-label">Not</p>
                </div>
              )}
          {item.Homework_Status && (
                <div className="info-item">
                  <p className="event-label">Durumu</p>
                </div>
              )}
            </div>

            <div>
              <div className="info-item">
                <p className="event-info">{moment(item.Homework_DateTime).format('DD/MM/YYYY')}</p>
              </div>
              <div className="info-item">
                <p className="event-info">{moment(item.Homework_DateTime).format('dddd')}</p>
              </div>
              <div className="info-item">
                <p className="event-info">
                  {item.Homework_DateTime.split('T')[1].slice(0, 5)}
                </p>
              </div>
             {item.Lesson && (
                <div className="info-item">
                  <p className="event-info">{item.Lesson.Lesson_Title}</p>
                </div>
              )}
            {item.HomeWork_Note && (
                <div className="info-item">
                  <p className="event-info">{item.HomeWork_Note}</p>
                </div>
              )}
           {  item.Homework_Status && (
                <div className="info-item">
                  <p className="event-info">{item.Homework_Status}</p>
                </div>
              )}
            </div>
          </div>

          <div className="button-row">
            {item.Homework_Status !== 'yapıldı' && (
              <button className="status-button green" onClick={() => _selectStatus('yapıldı', item.ID)}>
                Yapıldı
              </button>
            )}
       {  item.Homework_Status !== 'eksik' && (
              <button className="status-button gray" onClick={() => _selectStatus('eksik', item.ID)}>
                Eksik Yaptı
              </button>
            )}
            {item.Homework_Status !== 'yapılmadı' && (
              <button className="status-button red" onClick={() => _selectStatus('yapılmadı', item.ID)}>
                Yapılmadı
              </button>
            )}
          </div>
        </div>
  )):

         <div>{renderWeeklySchedule()}</div>}


        
                 {/*
                 renderEventSlots()
                 <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddProductCategory={false}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  /> */} 

        

            
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
