import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { getLimit as onGetLimit,
  getLimitTeacher as  onGetLimitTeacher
 } from "store/contacts/actions";
import moment from "moment";
import Logo from "../../../assets/images/logo-dark.png"

const ContactsList = (props) => {
  const [getUserType, setUserType] = useState(null);

  document.title = getUserType == 3 ? "Öğrenciler | EfsoKoç Koç PANELI" : "Koçlar | EfsoKoç Kullanıcı PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();

  const { users } = useSelector((state) => ({
    users: state.contacts.limit,
  }));

  useEffect(() => {
    if (users && !users.length) {

      let authUser = JSON.parse(localStorage.getItem("authUser"));

      console.log("şösdmfşl",authUser.Users_Type_ID)
      setUserType(authUser.Users_Type_ID)
      if(authUser.Users_Type_ID == 3){
        if (authUser.Teacher.length > 0) {
          dispatch(onGetLimitTeacher(authUser.Teacher[0].ID));
          }
      }
    else{
        dispatch(onGetLimit(authUser.ID));
      }
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
  }, [users]);

  const handleOrderClicks = (arg) => {

    console.log("ksdmfkş",arg)

    setCategoryID(arg.User.Users_Type_ID)
    setLessonTypeID(arg.User.Users_Lessons_Type_ID)


    setIsEdit(true);


    toggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Yönetim" breadcrumbItem="Koçlar" />

          {/* getUserType == 3 ? <Col md="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Öğrenci Ekle
              </Button>
            </div>
          </Col> : null */}

          <Row>
            {getUserType == 3 ? users.map((teacher) => {
              return(
              <Col lg="12" key={teacher.Users_ID} className="mb-4">
                <Card className="teacher-card">
                  <CardBody className="teacher-card-body">
                    <Row>
                      <Col lg="2" className="teacher-image-col">
                    {  teacher.Users.ProfilePhoto == null ? 
                      <img
                      src={Logo}
                          alt={"EfsoKoç"}
                          className="img-fluid teacher-image"
                        />:
                        <img
                          src={'https://megasubdomain.stechomeyazilim.info/' + teacher.Users.ProfilePhoto}
                          alt={teacher.Users.ProfilePhoto}
                          className="img-fluid teacher-image"
                        />}
                      </Col>
                      <Col lg="10">
                        <h3 className="teacher-name">
                          {teacher.Users.Users_NameSurname}
                        </h3>

                        
                        <p className="teacher-shortdesc">
                          {teacher.Users.Users_TelephoneNumber}
                        </p>

                     
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}) : users.map((teacher) => {
              console.log("şösmdfcş",teacher.Teacher)
              return(
              <Col lg="12" key={teacher.Users_ID} className="mb-4">
                <Card className="teacher-card">
                  <CardBody className="teacher-card-body">
                    <Row>
                      <Col lg="2" className="teacher-image-col">
                        <img
                          src={'https://megasubdomain.stechomeyazilim.info/' + teacher.Teacher.Teacher_Url}
                          alt={teacher.Teacher.Teacher_NameSurname}
                          className="img-fluid teacher-image"
                        />
                      </Col>
                      <Col lg="10">
                        <h3 className="teacher-name">
                          {teacher.Teacher.Users.Users_NameSurname}
                        </h3>

                        <p className="teacher-contact">
                        {teacher.Teacher.Users.Users_TelephoneNumber}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )})}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
