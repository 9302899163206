import PropTypes from 'prop-types'
import React,{useState} from "react"
import { Modal, ModalBody, Label,Input } from "reactstrap"

const DeleteModal = ({ show, onFilterClick, onCloseClick,selectedValuesCompany,getCategoryCompany,handleSelectChangeCompany }) => {

  const [getIsActive, setIsActive] = useState(false);
  const [getCompanyID, setCompanyID] = useState(false);


  const _handleSelectChangeCompany = (val) => {
    setCompanyID(val.target.value)

    console.log("valasda",val.target.value)
  
  }

  return (
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
         

                    <div className="mb-3">
                        <Label className="form-label">Kullanıcı Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                        
                          value={selectedValuesCompany} // Burada selectedValues, bileşenin seçili değerlerini içeren bir durumdur
                          onChange={(event) => _handleSelectChangeCompany(event)}
                        >
                            <option value="">Lütfen Seçim Yapınız</option>

                          {getCategoryCompany.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          ))}

                        </Input>
                       
                      </div>


        


          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" onClick={()=> handleSelectChangeCompany(getCompanyID,getIsActive)}>Filtrele</button>
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Kapat</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteModal
