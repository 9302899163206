import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios";

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  const [getUserType, setUserType] = React.useState("Admin");
  const [getSeenPage, setSeenPage] = React.useState([]);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser != null) {
      setUserType(authUser.Users_Type_ID)
    }

  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  useEffect(() => {
    getSearchAuth()

  }, []);

  const getSearchAuth = async () => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    if (authUser.Users_Type_ID != 1) {
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersAuthSearch/select/${authUser.Users_Type_ID}`)
        .then((res) => {

          setSeenPage(res.data)

          //setUserAuth(res.data)
        })
    }
  }
  return (
    getUserType == 1 || getUserType == 2 ?
     <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Anasayfa")} </li>

            <li>
              <Link to="/" >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Anasayfa")}</span>
              </Link>
            </li>

         
            <li>
              <Link to="/counter" >
                <i className="bx bx-user-pin"></i>
                <span>{props.t("Sayaçlar")}</span>
              </Link>
            </li>

            <li>
              <Link to="/offer" >
                <i className="bx bx-user-pin"></i>
                <span>{props.t("Koçlar")}</span>
              </Link>
            </li>

            <li>
              <Link to="/SSS" >
                <i className="bx bx-question-mark"></i>
                <span>{props.t("SSS")}</span>
              </Link>
            </li>

      
            <li>
              <Link to="/sliders" >
                <i className="bx bx-image"></i>
                <span>{props.t("Duyurular")}</span>
              </Link>
            </li>
            <li>
              <Link to="/about" >
                <i className="bx bx-info-circle"></i>
                <span>{props.t("Hakkımızda")}</span>
              </Link>
            </li>

            <li>
              <Link to="/salesPoint" >
                <i className="bx bx-bell"></i>
                <span>{props.t("Satış Noktaları")}</span>
              </Link>
            </li>

          
            <li>
              <Link to="/bildirim2" >
                <i className="bx bx-bell"></i>
                <span>{props.t("Bildirimler")}</span>
              </Link>
            </li>
          

            <li>
              <Link to="/payment2" >
                <i className="bx bx-calendar"></i>
                <span>{props.t("Alışkanlık Bildirimi")}</span>
              </Link>
            </li>


            <li>
              <Link to="/motivation" >
                <i className="bx bx-book-content"></i>
                <span>{props.t("Motivasyon Sözleri")}</span>
              </Link>
            </li>

            <li>
              <Link to="/book" >
                <i className="bx bx-border-all"></i>
                <span>{props.t("Denemeler")}</span>
              </Link>
            </li>


            <li>
              <Link to="/productsSubSubcategory" >
                <i className="bx bx-border-all"></i>
                <span>{props.t("Ödevler")}</span>
              </Link>
            </li>


            <li>
              <Link to="/brandCategory" >
                <i className="bx bx-border-all"></i>
                <span>{props.t("Planlama")}</span>
              </Link>
            </li>


            <li>
              <Link to="/productscategory" >
                <i className="bx bx-align-justify"></i>
                <span>{props.t("Hedefleme")}</span>
              </Link>
            </li>



            <li>
              <Link to="/products" >
                <i className="bx bx-border-outer"></i>
                <span>{props.t("Koç Görüşmesi")}</span>
              </Link>
            </li>

{/** 
           


            <li>
              <Link to="/productsSubSubcategory" >
                <i className="bx bx-border-all"></i>
                <span>{props.t("Konular")}</span>
              </Link>
            </li>
            

        
            <li>
              <Link to="/payment2" >
                <i className="bx bx-calendar"></i>
                <span>{props.t("Alt Başlık Gir")}</span>
              </Link>
            </li>


          
          
            <li>
              <Link to="/productsSubcategory" >
                <i className="bx bx-border-all"></i>
                <span>{props.t("Soru AltKategorileri")}</span>
              </Link>
            </li>

          
          
         
            <li>
              <Link to="/Blogs" >
                <i className="bx bx-border-outer"></i>
                <span>{props.t("Sorular")}</span>
              </Link>
            </li>
          
            <li>
              <Link to="/available" >
                <i className="bx bx-border-outer"></i>
                <span>{props.t("Pdr Kategori")}</span>
              </Link>
            </li>

            <li>
              <Link to="/pdr" >
                <i className="bx bx-border-outer"></i>
                <span>{props.t("Pdr")}</span>
              </Link>
            </li>
          
            <li>
              <Link to="/authorityList" >
                <i className="bx bx-user-check"></i>
                <span>{props.t("Cevaplanan Testler")}</span>
              </Link>
            </li>
            

            <li>
              <Link to="/dinamikForm" >
                <i className="bx bx-book-content"></i>
                <span>{props.t("Cevaplanan Pdr Testleri")}</span>
              </Link>
            </li>



            <li>
              <Link to="/post" >
                <i className="bx bx-book-content"></i>
                <span>{props.t("Gönderi")}</span>
              </Link>
            </li>

            <li>
              <Link to="/purch" >
                <i className="bx bx-book-content"></i>
                <span>{props.t("Satın Almalar")}</span>
              </Link>
            </li>

            <li>
              <Link to="/postCoach" >
                <i className="bx bx-book-content"></i>
                <span>{props.t("Koç Gönderi")}</span>
              </Link>
            </li>
            

       

            
*/}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment> :
        <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Anasayfa")} </li>
  
              <li>
                <Link to="/" >
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Anasayfa")}</span>
                </Link>
              </li>
  
           
              <li>
                <Link to="/counter" >
                  <i className="bx bx-user-pin"></i>
                  <span>{props.t("Sayaçlar")}</span>
                </Link>
              </li>
  
              <li>
                <Link to="/offer" >
                  <i className="bx bx-user-pin"></i>
                  <span>{props.t("Öğrenciler")}</span>
                </Link>
              </li>
  
              <li>
                <Link to="/SSS" >
                  <i className="bx bx-question-mark"></i>
                  <span>{props.t("SSS")}</span>
                </Link>
              </li>
  
        
              <li>
                <Link to="/sliders" >
                  <i className="bx bx-image"></i>
                  <span>{props.t("Duyurular")}</span>
                </Link>
              </li>
              <li>
                <Link to="/about" >
                  <i className="bx bx-info-circle"></i>
                  <span>{props.t("Hakkımızda")}</span>
                </Link>
              </li>
  
              <li>
                <Link to="/salesPoint" >
                  <i className="bx bx-bell"></i>
                  <span>{props.t("Satış Noktaları")}</span>
                </Link>
              </li>
  
            
              <li>
                <Link to="/bildirim2" >
                  <i className="bx bx-bell"></i>
                  <span>{props.t("Bildirimler")}</span>
                </Link>
              </li>
            
  
              <li>
                <Link to="/payment2" >
                  <i className="bx bx-calendar"></i>
                  <span>{props.t("Alışkanlık Bildirimi")}</span>
                </Link>
              </li>
  
  
              <li>
                <Link to="/motivation" >
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Motivasyon Sözleri")}</span>
                </Link>
              </li>
  
              <li>
                <Link to="/book" >
                  <i className="bx bx-border-all"></i>
                  <span>{props.t("Denemeler")}</span>
                </Link>
              </li>
  
  
              <li>
                <Link to="/productsSubSubcategory" >
                  <i className="bx bx-border-all"></i>
                  <span>{props.t("Ödevler")}</span>
                </Link>
              </li>
  
  
              <li>
                <Link to="/brandCategory" >
                  <i className="bx bx-border-all"></i>
                  <span>{props.t("Planlama")}</span>
                </Link>
              </li>
  
  
              <li>
                <Link to="/productscategory" >
                  <i className="bx bx-align-justify"></i>
                  <span>{props.t("Hedefleme")}</span>
                </Link>
              </li>
  
  
  
              <li>
                <Link to="/products" >
                  <i className="bx bx-border-outer"></i>
                  <span>{props.t("Koç Görüşmesi")}</span>
                </Link>
              </li>
  
  {/** 
             
  
  
              <li>
                <Link to="/productsSubSubcategory" >
                  <i className="bx bx-border-all"></i>
                  <span>{props.t("Konular")}</span>
                </Link>
              </li>
              
  
          
              <li>
                <Link to="/payment2" >
                  <i className="bx bx-calendar"></i>
                  <span>{props.t("Alt Başlık Gir")}</span>
                </Link>
              </li>
  
  
            
            
              <li>
                <Link to="/productsSubcategory" >
                  <i className="bx bx-border-all"></i>
                  <span>{props.t("Soru AltKategorileri")}</span>
                </Link>
              </li>
  
            
            
           
              <li>
                <Link to="/Blogs" >
                  <i className="bx bx-border-outer"></i>
                  <span>{props.t("Sorular")}</span>
                </Link>
              </li>
            
              <li>
                <Link to="/available" >
                  <i className="bx bx-border-outer"></i>
                  <span>{props.t("Pdr Kategori")}</span>
                </Link>
              </li>
  
              <li>
                <Link to="/pdr" >
                  <i className="bx bx-border-outer"></i>
                  <span>{props.t("Pdr")}</span>
                </Link>
              </li>
            
              <li>
                <Link to="/authorityList" >
                  <i className="bx bx-user-check"></i>
                  <span>{props.t("Cevaplanan Testler")}</span>
                </Link>
              </li>
              
  
              <li>
                <Link to="/dinamikForm" >
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Cevaplanan Pdr Testleri")}</span>
                </Link>
              </li>
  
  
  
              <li>
                <Link to="/post" >
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Gönderi")}</span>
                </Link>
              </li>
  
              <li>
                <Link to="/purch" >
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Satın Almalar")}</span>
                </Link>
              </li>
  
              <li>
                <Link to="/postCoach" >
                  <i className="bx bx-book-content"></i>
                  <span>{props.t("Koç Gönderi")}</span>
                </Link>
              </li>
              
  
         
  
              
  */}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
