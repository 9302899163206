import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import DateModal from "components/Common/DateModal";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import FilterModal from "components/Common/FilterModal";

import {
  getSubEducation as onGetSubEducation,
  addSubEducation as onAddSubEducation,
  updateSubEducation as onUpdateSubEducation,
  deleteSubEducation as onDeleteSubEducation,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Alışkanlık Bildirimi | EfsoKoç Dijital Kullanıcı PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getUser, setUser] = useState([]);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);
  const [getFilterModal, setFilterModal] = useState(false);
  const [getFilterData, setFilterData] = useState([]);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);
  const [getUserType, setUserType] = useState(null);
  const [getStudyID, setStudyID] = useState(null);

  const [getType, setType] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.LessonSubSubject_Title) || "",
      desc: (contactEdit && contactEdit.LessonSubSubject_Desc) || "",
      sort: (contactEdit && contactEdit.Sort) || "",

    },
    validationSchema: Yup.object({
    
    }),
    onSubmit:async (values) => {

      if (isEdit) {

        let authUser = JSON.parse(localStorage.getItem("authUser"));

        const testDateUtc = moment.utc()
        const localDate = moment(testDateUtc).local()

        const updateUser = {
          ID: contactEdit.ID,
          PracticeNotification_Admin_ID: getCategoryID,
          Users_ID:authUser.ID,
          Created_DateTime:localDate,
         // Reminder_Interval:
          IsActive:getIsActive,
          Notification_Seen:false
        };
        dispatch(onUpdateSubEducation(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {

        const testDateUtc = moment.utc()
        const localDate = moment(testDateUtc).local()

        let authUser = JSON.parse(localStorage.getItem("authUser"));

        const apiUrl = 'https://sihaadmin.stechomeyazilim.info:8182/addPracticeNotificationEfso/send'; // API URL'inizi buraya koyun


        const response = await axios.post(apiUrl, {
          PracticeNotification_Admin_ID: getCategoryID,
          Users_ID:getUserType == 3 ? selectedUserID :  authUser.ID,
          Created_DateTime:localDate,
          IsActive:getIsActive,
          Notification_Seen:false
        })
  
                 console.log("şlmsdlf",response)
  
     
        if (response.status === 200) {
          showToast('Efso Dijital', "Veriler başarıyla işlendi!", true)
          setModal(!modal);
        
          
         
            setTimeout(() =>  {
             
              _getAllHomework();
  
            }, 1000)
        } else {
  
          showToast('Efso Dijital', "Veriler işlenirken bir hata oluştu!", false)
  
        }

           validation.resetForm();
      }
      toggle();
    },
  });


  function showToast(type,message,type2) {

    let ele = null
  
     if(type2 == true){
       ele = "success"
       message = message
    } else{
       ele = "error"
       message = message 
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  
  const { users } = useSelector(state => ({
    users: state.contacts.educationSubItem,
  }));
  const [modal, setModal] = useState(false);
    const [getExcelModal, setExcelModal] = useState(false)

  const [isEdit, setIsEdit] = useState(false);
  const [getIsActive, setIsActive] = useState(false);

  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getNotificationPracticeAdminEfso/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }


  useEffect(() => {
    if (getCategory && !getCategory.length) {
      let authUser = JSON.parse(localStorage.getItem("authUser"));

      setUserType(authUser.Users_Type_ID)

    _getAuthData()
    }

  }, [getCategory]);

  const [getTeacherOfUser, setTeacherOfUser] = useState([]);
  const [getNotification, setNotification] = useState([]);
  const [selectedUserID, setSelectedUserID] = useState(null);


  const _getAllHomework = async () => {
    try {
      let authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser != null) {
        if (authUser.Users_Type_ID === 2) {
          await axios
            .get(`https://sihaadmin.stechomeyazilim.info:8182/getPracticeNotificationEfso/select/${authUser.ID}/`)
            .then((rsp) => {
                setNotification(rsp.data); 
            });
        } else {
          if (authUser.Teacher.length > 0) {
            await axios
              .get(`https://sihaadmin.stechomeyazilim.info:8182/getTeacherOfUserEfso2/select/${authUser.Teacher[0].ID}/`)
              .then(async (rsp1) => {

                setTeacherOfUser(rsp1.data)

                rsp1.data.map(async (item, index) => {
                  await axios
                    .get(`https://sihaadmin.stechomeyazilim.info:8182/getPracticeNotificationEfso/select/${item.Users_ID}/`)
                    .then((rsp) => {
                      const dates = [];
                      setNotification(rsp.data); 

                    });
                });
              });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    if (getNotification && getNotification.length === 0) {
      _getAllHomework();
    }
  }, [getNotification]);



  useEffect(() => {
    setContact(users);
   // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteSubEducation(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };




  const exportToCSVLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    console.log("lksdklf")
    let fileName = getSelectDate + getSelectDateFinish

    const data2 = users.filter(x => x.Payment_CreatedDateTime >= getSelectDate && x.Payment_CreatedDateTime <= getSelectDateFinish).map(elt => [
    elt.PaymentTotalAmount,
    elt.Payment_Type,
    elt.Users.Users_NameSurname,
    moment(elt.Payment_CreatedDateTime).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Tutar", t: "s" };
      ws["B1"] = { v: "Ödeme Tipi", t: "s" };
      ws["B1"] = { v: "Kullanıcı", t: "s" };
      ws["C1"] = { v: "Tarih", t: "s" };

      
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }
  const exportToCSVDontLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "Son1AyGirisYapmayanlar"


    const data2 = users.filter(x => x.Last_Login_Date < getSelectDate || x.Last_Login_Date > getSelectDateFinish).map(elt => [
    elt.NameSurname,
    moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
      ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }


  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 
  }


  const [notifications, setNotifications] = useState(users);

  const handleOrderDelete= (ID) => {
    axios.delete(`https://sihaadmin.stechomeyazilim.info:8182/deletePracticeNotificationEfso/${ID}`).then(async(res) => {
      _getAllHomework();
      showToast('Efso Dijital', "Veriler başarıyla silindi!", true)
    })

  };


  const handleSwitchChange = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.ID === id
          ? { ...notification, IsActive: !notification.IsActive }
          : notification
      )
    );
  };


  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"


    
    const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
      elt.NameSurname,
      elt.Last_Login_Date,
        ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
    ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };


    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }

    const handleCategoryChange = async(e) => {
      const selectedID = e.target.value;
      setSelectedUserID(selectedID);
    };
  

  const total = getFilterCheckModal == true ? getFilterData.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0) : users.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0);

  return (
    <React.Fragment>

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={getType == "login" ? exportToCSVLoginLastMonth: exportToCSVDontLoginLastMonth}
        show={getExcelModal}
        type={getType}
        onCloseClick={() => setExcelModal(false)}
      />
      

<FilterModal
        handleSelectChangeCompany= {_onFilterClick}
        show={getFilterModal}
        getCategoryCompany={getUser}
        onCloseClick={() => setFilterModal(false)}
     
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Alışkanlık Bildirimi Gir" />

        
          
          <Row>

            
            <Col lg="12">
              <Card>
                <CardBody>
      
               
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleUserClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Alışkanlık Bildirimi Ekle
              </Button>
            </div>
         

                <div className="table-container">
      <table>
        <thead>
          <tr>
        
            <th>Bildirim Türü</th>
            {getUserType == 3 ? <th>Kullanıcı</th> : null }
            <th>Oluşturulma Zamanı</th>
            <th>Hatırlatma Süresi</th>
            <th>Aktiflik Durumu</th>
            <th>Göründü Mü?</th>
            <th>Sil?</th>
          </tr>
        </thead>
        <tbody>
          {getNotification.map((notification) => (
            <tr key={notification.ID}>
           
              <td>{notification.PracticeNotification_Admin.PracticeNotification_Admin_Title}</td>
              {getUserType == 3 ? <td>{notification.Users.Users_NameSurname}</td> : null }
              <td>{moment(notification.Created_DateTime).format('DD/MM/YYYY HH:MM')}</td>
              <td>{notification.Reminder_Interval}</td>
              <td>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={notification.IsActive}
                    onChange={() => handleSwitchChange(notification.ID)}
                  />
                  <span className="slider round"></span>
                </label>
              </td>
              <td>{notification.Notification_Seen ? "Görüldü" : "Görülmedi"}</td>

              <td>
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> handleOrderDelete(notification.ID)}
              >
                <i className="mdi mdi-delete me-1" />
                Sil 
              </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    
              
                

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Alışkanlık Bildirimi Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                       
                        
                      
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                                          

                  <div className="mb-3">
                              <Label className="form-label">Açıklama Text</Label>
                              <Input
                                name="desc"
                                label="desc"
                                type="text"
                                placeholder="Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.desc || ""}
                                invalid={
                                  validation.touched.desc &&
                                    validation.errors.desc
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.desc &&
                                validation.errors.desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.desc}
                                </FormFeedback>
                              ) : null}
                            </div>
                          

                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="number"
                                placeholder="Sıralama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>
                            


                            <div className="d-flex">
                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>


                    
  {  getUserType == 3 ?
    <div className="mb-3">
    <Label className="form-label">Öğrenci Seçiniz</Label>
      <Input
        name="selectedUserID"
        type="select"
        className="form-select"
        defaultValue={selectedUserID}
        onChange={handleCategoryChange}
        value={selectedUserID}>
         <option value="">Lütfen Seçim Yapınız</option>

        {getTeacherOfUser.map((item, index) => (
          <option value={item.Users.ID} key={item.Users.ID}>{item.Users.Users_NameSurname}</option>
        ))}

      </Input>
     
    </div>
: null}
                        
                        <div className="mb-3">
                              <Label className="form-label">Konu Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>


                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.PracticeNotification_Admin_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>


                         

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
